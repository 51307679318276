.first h4 {
  color: #5f6974;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-style: normal;
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #73808f;
}

.logos {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(17%, 1fr)
  ); /* Responsive grid layout */
  gap: 1rem; /* Space between logos */
  justify-items: center; /* Center logos horizontally */
  align-items: center; /* Center logos vertically */
}

.logo img {
  max-width: 100%;
  height: auto;
  display: block;
}
