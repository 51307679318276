.course-card {
  margin-bottom: 20px;
  padding: 1rem;
  border: none;
}
.course-image {
  height: 200px;
  object-fit: cover;
}
.course-details {
  padding: 10px;
}
.course-title h4 {
  font-size: 17px;
  /* font-weight: bold; */
}

.course-price-duration {
  margin: 10px 0;
}
.course-price-duration #duration,
.course-ratings #reviews {
  font-size: 13px;
}
.course-excerpt {
  font-size: 0.9em;
  color: #666;
}
.learning-mode-button {
  margin-right: 5px;
  margin-bottom: 5px;
}
.course-details button {
  font-size: 10px;
}
