.custom-opacity {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.custom-opacity:focus {
  opacity: 1;
}

.custom-select {
  position: relative;
  cursor: pointer;
}

.custom-select .select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 0.3s ease;
}

.custom-select .select-arrow.rotate {
  transform: translateY(-50%) rotate(180deg);
}

.application-form {
  font-size: 0.9rem;
}

.application-form h2 {
  font-size: 1.5rem;
}

.application-form p,
.application-form .form-label,
.application-form .form-control {
  font-size: 0.85rem;
}
.application-form .form-control::placeholder {
  font-size: 0.85rem;
}

.application-btn {
  background-color: var(--background-color-btn);
  border: none;
  transition: background-color 0.3s ease;
}

.application-btn:hover {
  background-color: var(--button-hover-color);
}

.select-arrow {
  transition: transform 0.3s ease; /* Smooth transition for rotation */
  cursor: pointer;
}

.rotate {
  transform: rotate(180deg); /* Rotate the icon */
}
