.hero-section {
  padding: 50px 0;
  background-color: var(--background-color-hero);
}

.hero-text h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 700;
}
.hero-text span {
  color: var(--text-color-span);
}

.hero-text p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.hero-text button {
  font-size: 1.2em;
  padding: 10px 20px;
}

.hero-image {
  position: relative;
  text-align: center;
}

.dynamic-image {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.image-cards {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.detail-card {
  width: 30%;
  border-width: 3px;
  text-align: center;
}

.detail-card .card-text:first-child {
  font-size: 2em;
  font-weight: bold;
}

.detail-card .card-title {
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 5px;
}

.detail-card .card-text:last-child {
  font-size: 0.9em;
}
