.hero-section {
  padding: 60px 0;
  background-color: #f8f9fa; /* Optional: Background color for the hero section */
}

h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}
h3#hero-text {
  background: linear-gradient(94.5deg, #135dff 53.99%, #f59d42 94.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

p {
  font-size: 0.9rem;
  margin-bottom: 30px;
  line-height: 2rem;
}

.contact-button {
  background-color: #002cb0;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
}

.contact-button:hover {
  background-color: #001a80; /* Darker shade for hover effect */
}

.img-fluid {
  max-width: 100%;
  height: auto;
border-radius: 20px;

}
