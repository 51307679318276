.pricing-section {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.pricing-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.pricing-card .card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pricing-card h6 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.pricing-card p#desc {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 1rem;
}

.pricing-card h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
}

.pricing-card ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.pricing-card ul li .list-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.pricing-card .btn {
  margin-top: auto;
}
