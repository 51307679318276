.pricing-section p#desc {
  font-size: 12px;
}
.pricing-section ul {
  list-style: none;
  padding-left: 0;
}

.pricing-section li {
  font-size: 12px; /* Adjust the font size as needed */
  line-height: 2.5; /* Adjust the line height as needed */
}

.pricing-section .list-icon {
  margin-right: 8px; /* Optional: Add some space between the icon and text */
}
