.AppHeader h1 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  color: #5f6974;
  opacity: 0px;
}

.AppHeader p {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 400;
  color: #73808f;
}
