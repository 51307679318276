/* Colors */
:root {
  --background-color-footer: #cfebff;
  --background-color-hero: #f5f8f8;
  --background-color-contact:#f1f9fe;
  --background-color-btn:#002cb0;
  --button-hover-color:#0041e4;
  --border-color-footer: #dee2e6;
  --text-color-heading: #515151;
  --text-color-link: #6a6b6b;
  --text-color-span: #0041e4;
  --text-color-copyright: #6c757d;
  

  /* Font sizes */
  --font-size-heading: 1.5em;
  --font-size-link: 14px;
  --font-size-copyright: 0.9em;

  /* Font weights */
  --font-weight-heading: 500;
  --font-weight-link: 400;

  /* Spacing */
  --spacing-footer-padding: 20px;
  --spacing-section-margin: 10px;
  --spacing-list-item-margin: 10px;
  --spacing-icon-margin: 10px;
  --spacing-copyright-margin-top: 20px;

  /* Border */
  --border-footer-top: 1px solid var(--border-color-footer);
}
