.learning-approach-section {
  margin: 20px 30px;
}
.learning-approach-section h3 {
  font-weight: 700;
  font-size: 35px;
}
.learning-approach-section p {
  line-height: 2rem;
  margin: 2rem 0;
}
#hero-text {
  background: linear-gradient(94.5deg, #135dff 53.99%, #f59d42 94.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-bullets {
  list-style: none;
  padding-left: 0;
}

.custom-bullets li {
  margin-bottom: 10px;
  padding-left: 30px;
  background-repeat: no-repeat;
  background-position: 0 0.2em;
}

.custom-bullets li:nth-child(1) {
  background-image: url("https://res.cloudinary.com/dilc7oqpu/image/upload/v1721728834/Innkeeper%20App/about-page/Group_r868iw.svg");
}

.custom-bullets li:nth-child(2) {
  background-image: url("https://res.cloudinary.com/dilc7oqpu/image/upload/v1721728911/Innkeeper%20App/about-page/Group_tiidba.svg");
}

.custom-bullets li:nth-child(3) {
  background-image: url("https://res.cloudinary.com/dilc7oqpu/image/upload/v1721728878/Innkeeper%20App/about-page/Group_lukeuu.svg");
}
