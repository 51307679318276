.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
}

.accordion-button::after {
  filter: invert(0%);
}

.accordion-button.collapsed {
  background-color: transparent;
}
.faq-btn{
   background-color: var(--background-color-btn);
  border: none;
  transition: background-color 0.3s ease;
}

.faq-btn:hover{
   background-color: var(--button-hover-color);
}