.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--spacing-footer-padding);
  background-color: var(--background-color-footer);
  border-top: var(--border-footer-top);
}

.section {
  flex: 1;
  margin: var(--spacing-section-margin);
}

.heading {
  font-size: var(--font-size-heading);
  margin-bottom: var(--spacing-list-item-margin);
  color: var(--text-color-heading);
  font-weight: var(--font-weight-heading);
}

.list {
  list-style: none;
  padding: 0;
}

.list-item {
  margin-bottom: var(--spacing-list-item-margin);
}

.list-item a {
  text-decoration: none;
  color: var(--text-color-link);
  font-weight: var(--font-weight-link);
  font-size: var(--font-size-link);
}

.list-item a:hover {
  text-decoration: underline;
}

.fa-icon {
  margin-right: var(--spacing-icon-margin);
}

.copyright {
  width: 100%;
  text-align: center;
  margin-top: var(--spacing-copyright-margin-top);
  font-size: var(--font-size-copyright);
  color: var(--text-color-copyright);
}
