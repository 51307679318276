.contact-background {
  width: 100%;
  background-color: var(--background-color-contact);
}

.contact-container {
  padding: 30px;
  max-width: 100%;
  background-color: var(--background-color-contact);
}

.custom-opacity {
  opacity: 0.8;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ball {
  padding: 1rem;
}

.contact-btn{
  background-color: var(--background-color-btn);
  border: none;
  transition: background-color 0.3s ease;
}

.contact-btn:hover{
  background-color: var(--button-hover-color);
}
